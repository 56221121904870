





























































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_OVERVIEW } from '@/store/adminPanel/actions.type'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { BaseModel, Embedded } from '@/store/adminPanel/types'
import { BASE_URL } from '@/common/api.service'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import formTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import Breadcrumps from '@/components/menus/Breadcrumps.vue'
import { MenuEntry } from '@/store/types'
import { isUUID, parseDateToOutputDate } from '@/common/globals'
import moment from 'moment'
import { parseDateTime } from '@/common/datePicker'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import MetaComponent from '@/common/MetaComponent.vue'
import { downloadRessource } from '@/common/download'
@Component({
  components: { ConfirmationModal, Breadcrumps, BaseForm, SearchResult, ResultsPage }
})
export default class ContentAdministration extends MetaComponent {
  @Action(GET_OVERVIEW) getOverview: () => Promise<Embedded>

  results: BaseModel[] = []
  modelList: any = []
  modelFields: Form[] = []
  model: any = null
  searchModel: {text: string} = { text: '' }
  createLink = ''
  ressourceLink = ''
  selfLink = ''
  maxPerColumn = 3
  status = 'LOADING'
  currentPage = 1

  @Watch('$route.query.page')
  onPageChange (newPage: number) {
    this.currentPage = newPage
  }

  get headerKeys (): string[] {
    return [
      'id',
      'created',
      'lastModified'
    ]
  }

  get ignoreKeys (): string[] {
    return [
      'profileType'
    ]
  }

  get sortable (): { key: string; label: string}[] {
    return [
      { key: 'type', label: this.$i18n.t('personal-information.firstName').toString() }
    ]
  }

  get breadcrumps (): MenuEntry[] {
    const values = Object.values(this.$route.params)
    return values.length > 0 ? values.map((p, i) => {
      const route = values.slice(0, i + 1).join('/')
      return { name: this.$te('panel.' + (p || 'panel')) ? this.$t('panel.' + (p || 'panel')).toString() : p, route, disabledIndicator: null, children: [] }
    }) : []
  }

  showEditor = false

  get searchFields (): Form[] {
    return [{ type: formTypes.TEXT, label: this.$i18n.t('search-agent.search-string').toString(), key: 'text', id: 'text', md: 12 }]
  }

  getInputType (model: any, key: any): string {
    const type = model._types[key]
    if (typeof key === 'boolean') {
      return formTypes.CHECKBOX
    } else if (moment(key).isValid()) {
      return formTypes.DATETIME
    } else if (type === 'Set') {
      return formTypes.MULTISELECT
    } else if (type === 'LocalDateTime') {
      return formTypes.DATETIME
    } else if (type === 'LocalDate') {
      return formTypes.DATE
    } else if (model['_possible_' + key]) {
      return formTypes.SELECT
    } else if (type === 'UUID') {
      return formTypes.SELECT
    } else if (type === 'Boolean' || type === 'boolean') {
      return formTypes.CHECKBOX
    }
    return formTypes.TEXT
  }

  showDSGVOSpinner = false
  csvContent = '\uFEFF'
  resultArray: Array<Array<{key: string; value: any}>> = []

  downloadRessource (fileName: string): void {
    downloadRessource(this, this.ressourceLink, fileName)
  }

  downloadAccount (): void {
    this.showDSGVOSpinner = true
    Promise.all([
      Vue.axios.get(`${BASE_URL}panel/${this.$route.params.name}/${this.$route.params.id}`).then(data => {
        const account = Object.keys(data.data).filter(obj => !obj.startsWith('_')).map(key => ({ key, value: data.data[key] }))
        this.resultArray.push(account)
        return this.recursiveLoadChild(data.data._links, 'Account')
      })
    ]).then(() => {
      setTimeout(() => {
        const newArr = this.resultArray.map(ele => {
          const filtered = ele.filter(e => e.value)
          return [filtered.map(e => e.key), filtered.map(e => e.value)]
        })
        const headerKeys = newArr.flat().filter((e, i) => (i % 2 === 0)).flat().join(';')
        const content = newArr.flat().filter((e, i) => (i % 2 !== 0)).flat().join(';')
        this.csvContent += headerKeys + '\r\n' + content
        const a = document.createElement('a')
        a.href = 'data:attachment/csv,' + encodeURIComponent(this.csvContent)
        a.target = '_blank'
        a.download = 'DSGVO_gespeicherte_daten.csv'
        document.body.appendChild(a)
        a.click()
        this.showDSGVOSpinner = false
      }, 4000)
    })

    // let csvContent = 'data:text/csv;charset=utf-8,'
    // csvContent += Object.values
    // window.open(encodeURI(csvContent))
  }

  async recursiveLoadChild (links: any, parent: string): Promise<Promise<string>[]> {
    return Object.keys(links).filter(key => !key.startsWith('_') && key !== parent && key !== 'self').map(key => {
      return Vue.axios.get(links[key].href.replace('.de', '.de/api')).then((data: {data: {_embedded: object}}) => {
        return Object.values(data.data._embedded)[0].map((emb: any) => {
          const name = links[key].href.split('/')
          this.recursiveLoadChild(emb._links, name[name.length - 1])
          this.resultArray.push(Object.keys(emb).filter(key => !key.startsWith('_') && key !== parent && key !== 'self').map(key => ({ key, value: emb[key] })))
          return this.resultArray
        })
      })
    })
  }

  resetPage () {
    this.currentPage = 1
    history.pushState({}, '', `${this.$route.path}?page=${this.currentPage}&search=${this.searchModel.text}`)
  }

  goToPage (entry: MenuEntry): void {
    if (process.env.VUE_APP_NODE_ENV === 'production') {
      const protocol = window.location.protocol ? window.location.protocol : 'http:'
      window.location.href = protocol + '//' + window.location.host + '/panel' + entry.route
    } else {
      const protocol = window.location.protocol ? window.location.protocol : 'http:'
      window.location.href = protocol + '//' + window.location.host + '/panel' + entry.route
    }
  }

  getSelectOptions (type: any, model: any, key: any) {
    if (type === formTypes.MULTISELECT) {
      return model['_possible_' + key] ? model['_possible_' + key].map((v: KeyValue) => ({ key: v, value: v, name: v, text: this.$te('panel.' + v) ? this.$t('panel.' + v) : v })) : []
    } else if (type === formTypes.SELECT) {
      return model['_possible_' + key] ? model['_possible_' + key].map((v: KeyValue) => ({ key: v, value: v, name: v, text: this.$te('panel.' + v) ? this.$t('panel.' + v) : v })) : []
    } else {
      return []
    }
  }

  changePage (e: number): void {
    this.currentPage = e
    history.pushState({}, '', `${this.$route.path}?page=${e}&search=${this.searchModel.text}`)
  }

  loadModelFields (): void {
    if (this.model) {
      Object.keys(this.model).map((key) => {
        const type = this.getInputType(this.model, key)
        if (!key.startsWith('_') && !this.headerKeys.includes(key) && !this.ignoreKeys.includes(key) && !isUUID(this.model[key])) {
          this.modelFields.push({
            id: key,
            key: key,
            type,
            label: this.$i18n.t('panel.' + key).toString(),
            fullWidth: true,
            options: this.getSelectOptions(type, this.model, key)
          })
        }
      })
    }

    const possibleValues = Object.keys(this.model._links).filter(link => link.startsWith('_possible_'))
    if (possibleValues.length > 0) {
      possibleValues.map(value => {
        const link = process.env.VUE_APP_NODE_ENV === 'production' ? BASE_URL + this.model._links[value].href.split('.de/')[1] : this.model._links[value].href
        this.$root.$emit('load')
        Vue.axios.get(link).then((data) => {
          this.modelFields.map(f => {
            if (value.includes(f.key)) {
              this.$set(f, 'options', data.data._embedded[Object.keys(data.data._embedded)[0]].map((d: any) => ({ id: d.id, value: d.id, text: d._displayName, name: d._displayName })))

              if (f.type === formTypes.MULTISELECT) {
                this.$set(this.model, f.key, this.model[f.key].map((m: KeyValue | string) => {
                  const options = f.options as KeyValue[]
                  return (options && typeof m !== 'string') ? options.find((opt: KeyValue) => m.value === opt.id || m.value === opt.name) : m
                }))
              }
            }
          })
        }).finally(() => {
          this.$root.$emit('end-load')
        })
      })
    }
  }

  get links (): any {
    if (this.model) {
      return Object.keys(this.model._links).filter(link => !link.startsWith('_') && !link.startsWith('self') && link !== this.$route.params.name && link !== this.$route.params.childType).map(link => ({ value: link, text: this.$te('panel.' + link) ? this.$t('panel.' + link).toString() : link }))
    } else {
      return []
    }
  }

  get searchResults (): BaseModel[] {
    return this.results.map(item => ({ ...item, _displayName: this.$te('panel.' + item._displayName) ? this.$t('panel.' + item._displayName).toString() : item._displayName })).filter(item => item._displayName && item._displayName.toLowerCase().includes(this.searchModel.text.toLowerCase()))
  }

  parseDateTime (time: string): string {
    if (!moment(time).isValid()) {
      return time
    }
    return `${parseDateToOutputDate(time, this.$i18n.locale)} ${this.$i18n.t('panel.time')}`
  }

  parseModelToForm (model: any): any {
    if (model) {
      Object.keys(model).map((key) => {
        if (!key.startsWith('_') && !this.headerKeys.includes(key) && !this.ignoreKeys.includes(key) && !isUUID(model[key])) {
          if (this.getInputType(model, key) === formTypes.DATETIME) {
            model[key] = new Date(model[key])
          } else if (this.getInputType(model, key) === formTypes.MULTISELECT) {
            model[key] = model[key].map((v: KeyValue | string) => ({ key: v, value: v, name: v, text: v }))
          }
        }
      })
    }
    return model
  }

  goBack (): void {
    window.history.back()
  }

  goToNextLevel (item: any): void {
    if (process.env.VUE_APP_NODE_ENV === 'production') {
      const protocol = window.location.protocol ? window.location.protocol : 'https:'
      if (item._type) {
        window.location.href = protocol + '//' + window.location.host + '/panel/' + item._type
      } else if (item._links) {
        const link = item._links.self.href.split('.de')[1]
        window.location.href = protocol + '//' + window.location.host + link
      } else {
        window.location.href = item.href
      }
    } else {
      const protocol = window.location.protocol ? window.location.protocol : 'http:'
      if (item._type) {
        window.location.href = protocol + '//' + window.location.host + '/panel/' + item._type
      } else if (item._links) {
        const link = item._links.self.href.split('localhost:8080')[1]
        window.location.href = protocol + '//' + window.location.host + link
      } else {
        window.location.href = item.href.replace('8080', '8081')
      }
    }
  }

  create (): void {
    if (this.createLink) {
      let feedbackInfo: string, feedbackHeadline: string, responseState: boolean
      Vue.axios.post(this.createLink.replace('.de', '.de/api'), {}).then((data) => {
        if (this.modelList.length) {
          this.modelList.push(data.data)
        } else {
          this.model = this.parseModelForDownload(data.data)
          this.loadModelFields()
          if (this.model && this.model._links && this.model._links.self) {
            this.selfLink = this.model._links.self.href
          }
        }
        this.showEditor = true
      }, error => {
        feedbackHeadline = error.status + ' ' + error.error
        feedbackInfo = error.message
        responseState = false
        this.$root.$emit('alert', feedbackHeadline, feedbackInfo, !responseState)
      })
    }
  }

  update (model: any): void {
    let feedbackInfo: string, feedbackHeadline: string, responseState: boolean
    this.selfLink = model.id && model._links && model._links.self ? model._links.self.href.replace('.de', '.de/api') : this.selfLink.replace('.de', '.de/api')
    model = this.parseModelForUpdate(model)
    const request = model.id ? Vue.axios.put(this.selfLink, model) : Vue.axios.post(this.createLink.replace('.de', '.de/api'), model)

    request.then(() => {
      feedbackInfo = ''
      feedbackHeadline = this.$t('info.' + (model.id ? 'save-success' : 'create-success'), { what: this.$t('data') }).toString()
      responseState = true
      if (process.env.NODE_ENV === 'production') {
        location.reload()
      } else {
        this.loadData()
      }
    }, error => {
      feedbackHeadline = error.status + ' ' + error.error
      feedbackInfo = error.message
      responseState = false
    }).finally(() => {
      this.$root.$emit('alert', feedbackHeadline, feedbackInfo, !responseState)
    })
  }

  deleteItem (model: any, i: any): void {
    let feedbackInfo: string, feedbackHeadline: string, responseState: boolean
    Vue.axios.delete(model._links.self.href.replace('.de', '.de/api'), {}).then(() => {
      feedbackInfo = ''
      feedbackHeadline = this.$t('info.save-success', { what: this.$t('actions.save') }).toString()
      responseState = true
      if (!this.modelList || this.modelList.length <= 1) {
        this.goBack()
      } else {
        this.loadData()
      }
    }, error => {
      feedbackHeadline = error.status + ' ' + error.error
      feedbackInfo = error.message
      responseState = false
    }).finally(() => {
      this.$root.$emit('alert', feedbackHeadline, feedbackInfo, !responseState)
      this.$bvModal.hide('confirmation-modal-delete-' + i)
      if (process.env.NODE_ENV === 'production') {
        location.reload()
      } else {
        location.reload()
        // this.loadData()
      }
    })
  }

  parseModelForUpdate (model: any): any {
    if (model) {
      this.modelFields.map(field => {
        const type = field.type
        if (model[field.key] !== null && (type === formTypes.DATETIME)) {
          model[field.key] = parseDateTime(model[field.key])
          this.$set(model, field.key, parseDateTime(model[field.key]))
        } else if (model[field.key] !== null && (type === formTypes.DATE)) {
          this.$set(model, field.key, parseDateTime(model[field.key], true))
        } else if (type === formTypes.MULTISELECT) {
          this.$set(model, field.key, model[field.key].map((v: KeyValue) => v.value))
        }
      })
    }
    return model
  }

  parseModelForDownload (model: any): any {
    if (model) {
      Object.keys(model).map((key) => {
        const type = model._types[key]
        if (model[key] && (type === 'LocalDate' || type === 'LocalDateTime')) {
          model[key] = new Date(model[key])
        } else if (type === formTypes.SET) {
          model[key] = model[key].map((v: KeyValue) => v.value)
        }
      })
    }
    return model
  }

  loadData (): void {
    this.$root.$emit('load')
    this.showEditor = !!(this.$route.params && this.$route.params.id)
    const name = this.$route.params.name
    const id = this.$route.params.id
    const childType = this.$route.params.childType

    this.results = []
    this.modelList = []
    this.modelFields = []
    this.model = null
    this.searchModel = { text: '' }
    this.createLink = ''
    this.selfLink = ''
    this.maxPerColumn = 3
    this.status = 'LOADING'
    this.currentPage = 1

    if (this.$route.query) {
      this.currentPage = +this.$route.query.page || 1
      this.searchModel.text = typeof this.$route.query.search === 'string' ? this.$route.query.search : ''
    }

    if (this.$route.params && this.$route.params.name && this.$route.params.id && this.$route.params.childType) {
      Vue.axios.get(`${BASE_URL}panel/${name}/${id}/${childType}`).then(data => {
        const obj = data.data._embedded
        const links = data.data._links
        if (obj) {
          const key = Object.keys(obj)[0]
          this.model = this.parseModelForDownload(obj[key][0])
          this.modelList = obj[key].map((model: any) => this.parseModelToForm(model))
          if (this.model && this.model._links && this.model._links.self) {
            this.selfLink = this.model._links.self.href
          }
        }

        if (links && links._create) {
          this.createLink = links._create.href
        }

        this.status = 'DATA'
        this.loadModelFields()
      }).catch(() => {
        this.status = 'NO_DATA'
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    } else if (this.$route.params && this.$route.params.name && this.$route.params.id) {
      Vue.axios.get(`${BASE_URL}panel/${name}/${id}`).then(data => {
        this.model = this.parseModelForDownload(data.data)
        if (this.model && this.model._links && this.model._links.self) {
          this.selfLink = this.model._links.self.href
        }
        this.status = 'DATA'
        this.loadModelFields()
        this.parseModelToForm(this.model)
        const links = data.data._links

        if (links && links._create) {
          this.createLink = links._create.href
        }

        if (links && links._resourceLink) {
          this.ressourceLink = links._resourceLink.href
        }
      }).catch(() => {
        this.status = 'NO_DATA'
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    } else if (this.$route.params && this.$route.params.name) {
      Vue.axios.get(BASE_URL + 'panel/' + this.$route.params.name).then(data => {
        const obj = data.data._embedded
        const links = data.data._links

        if (obj) {
          const key = Object.keys(data.data._embedded)[0]
          this.results = obj[key]
        }

        if (links && links._create) {
          this.createLink = links._create.href
        }
        this.status = 'DATA'
      }).catch(() => {
        this.status = 'NO_DATA'
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    } else {
      this.getOverview().then(data => {
        this.results = data.baseModelList
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }
  }

  created (): void {
    this.loadData()
  }
}
